<template>
  <div class="dataset-table__values-nonscale">
    <!--------------------------------------------
    |
    | EDIT OPTIONS
    |
    --------------------------------------------->
    <button
      class="table__toolbar-btn"
      @click="isEditingLabels = true"
      v-if="!hideOptions && isCategoryType"
    >
      <SvgIconDecorative icon="tag" />
      <VisibleText>Edit Labels</VisibleText>
    </button>

    <!--------------------------------------------
    |
    | VALUES
    |
    --------------------------------------------->
    <article class="dataset-table__values-nonscale-section">
      <h2 class="dataset-table__values-header">
        <VisibleText>values</VisibleText>
      </h2>
      <p
        style="text-align: center"
        class="error-msg__block"
        v-if="clientQuestion.unique_values.length === 0"
      >
        <VisibleText>No responses</VisibleText>:
      </p>
      <ul
        :class="
          isCategoryType
            ? 'dataset-table__values-ul--cat'
            : 'dataset-table__values-ul'
        "
        v-else
      >
        <li
          v-for="(value, index) in clientQuestion.unique_values"
          :key="`${clientQuestion._id.$oid}-vals-${index}`"
          class="dataset-table__values-li"
        >
          <EditLabelInput
            :unique-val="value"
            @update-label="$_dataValuesMixin_updateLabel"
            v-if="isEditingLabels && isCategoryType"
          >
            {{ value.value }}
          </EditLabelInput>
          <span class="dataset-table__values-li__item" v-else>
            <span class="value">
              {{ value.value }}
            </span>
            <span
              class="label"
              :data-parent="value.label ? $options.name : null"
              v-show="isCategoryType"
            >
              {{ value.label || translate("no label", $options.name) }}
            </span>
          </span>
        </li>
      </ul>
    </article>

    <!--------------------------------------------
    |
    | SAVE OPTIONS
    |
    --------------------------------------------->
    <div v-show="isEditingLabels">
      <button
        class="dataset-table__values-save-btn"
        @click="$_dataValuesMixin_saveLabels"
      >
        <SvgIconDecorative icon="check" />
        <VisibleText>Save Labels</VisibleText>
      </button>
      <button class="btn-cancel" @click="isEditingLabels = false">
        <SvgIconDecorative icon="remove" />
        <VisibleText>Cancel</VisibleText>
      </button>

      <MessageInline :message="message.text" :message-type="message.type" />
    </div>
  </div>
</template>

<script>
// Components
import EditLabelInput from "./Components/EditLabelInput.vue"
import MessageInline from "@/components/UI/Message/MessageInline.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DataValuesMixin from "@/components/Dataset/Mixins/dataValuesMixin.js"

export default {
  name: "DatasetTableValuesList",
  mixins: [DataValuesMixin],
  components: {
    EditLabelInput,
    MessageInline,
    SvgIconDecorative
  },
  props: {
    clientQuestion: {
      default: () => {},
      type: Object,
      required: true
    },
    hideOptions: {
      default: () => true,
      type: Boolean
    }
  },
  data() {
    return {
      isEditingLabels: false
    }
  },
  computed: {
    isCategoryType() {
      return (
        this.clientQuestion.approved_data_type &&
        this.clientQuestion.approved_data_type === "CAT"
      )
    }
  }
}
</script>
